// @flow

import * as React from 'react'
import { Store } from 'Store.js'

import Rates from 'components/Rates'
import Cashback from 'components/Cashback'

import supportedThemes from './themes/__supportedThemes.js'
import { Currency } from 'i18n/NumberFormatter'
import { withTranslation } from 'react-i18next'
import cn from 'classnames'
import transDomain from './translations/index.translations'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'

function RegisterGroup (props) {
    const { t, theme } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const incentives = state.campaign.incentives.manufacturer
    const hiddenCashBack = state.campaign.incentives.manufacturer?.cashBack === 0
    return (
        <div className={cn(styles.container, {
            [styles.areCombinable]: incentives?.areCombinable,
            [styles.hiddenCashBack]: hiddenCashBack,
            [styles.shadowLess]: props.shadowLess,
            [styles.withIncentives]: !props.incentives
        })}>
            <div className={styles.voucher} style={{
                backgroundColor: theme.backgroundColor,
            }}>
                <h3 style={{
                    color: theme.textColor
                }} className={styles.title}>
                    {t('title', {
                        province: state.campaign.client.address.province,
                        voucher: state.campaign.incentives.voucher.name,
                        value: state.campaign.incentives.voucher.value,
                        type: state.campaign.incentives.voucher.type
                    })}
                </h3>
                <span
                    className={styles.voucherValue}
                    style={{
                        color: theme.valueColor
                    }}
                >
                    {currencyFormatter.format(voucher.value)}
                </span>
            </div>

            {incentives && (
                <>
                    <div className={styles.cashbackWrapper}>
                        <Cashback />
                    </div>

                    {incentives.interestRate !== null && (
                        <div className={styles.ratesWrapper}>
                            <Rates />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterGroup))
