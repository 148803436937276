// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes.js'

import MainLogo from '../MainLogo'
import { Store } from 'Store'

function Header (props) {
    const { theme } = props
    const { state } = React.useContext(Store)
    return (
        <>
            <div
                className={styles.bgWrapper}
                style={{
                    '--mask': `url(${theme.mask})`,
                    '--mask-tablet': `url(${theme.maskTablet})`,
                    '--mask-mobile': `url(${theme.maskMobile})`
                }}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo title={state.campaign.client.name} />
                    </div>
                    <div
                        className={styles.cars}
                        style={{
                            '--cars': `url(${theme.cars})`,
                            '--cars-mobile': `url(${theme.carsMobile})`,
                            '--cars-tablet': `url(${theme.carsTablet})`
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(Header)
