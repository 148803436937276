// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import Button, { ButtonGroup } from 'components/Button'

import transDomain from './translations/index.translations'

import { Store } from 'Store'
import * as Form from 'components/Form'
import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import styles from './styles.module.scss'

const DEFAULT_MILEAGE_OPTIONS = [5000, 15000, 30000, 50000, 70000, 90000, 100000]

type Props = WithFormProps | {
    values: {
        currentVehicleMileage: ?number,
        currentVehicleCondition: ?string,
        currentVehicleFinanceMethod: ?string,
        currentVehicleLeasePayment: ?string,
        currentVehicleLeasePaymentsLeft: ?string,
        currentVehicleFinancePayment: ?string,
        currentVehicleFinanceAmountOwing: ?string,
        storeLocationId: ?string,
        newVehicleMake: ?string,
        newVehicleModel: ?string,
        salesperson: ?string,
        timeline: ?string,
    }
}

function Step2 (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)

    const makes = state.campaign.models.map(model => model.make).filter((value, index, self) => self.indexOf(value) === index)

    if (makes.length === 1 && props.values.newVehicleMake !== makes[0]) {
        props.handleFieldChange('newVehicleMake', makes[0])
    }

    const defaultMileageOption = React.useMemo(() => {
        return props.values.currentVehicleMileage
            ? DEFAULT_MILEAGE_OPTIONS.reduce(function (prev, curr) {
                return (Math.abs(curr - props.values.currentVehicleMileage) < Math.abs(prev - props.values.currentVehicleMileage) ? curr : prev)
            })
            : ''
    }, [props.values.currentVehicleMileage])

    return (
        <div className={styles.container}>
            <Steps step={2} title={t('section.currentVehicleInfo')} description={t('description')} />

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.currentVehicleYear || null}
                            defaultValue={props.values.currentVehicleYear || null}
                            onChange={value =>
                                props.handleFieldChange('currentVehicleYear', parseInt(value, 10))
                            }
                            type='text'
                            label={t('label.vehicleYear')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.currentVehicleMake || null}
                            defaultValue={props.values.currentVehicleMake || null}
                            onChange={value =>
                                props.handleFieldChange('currentVehicleMake', value)
                            }
                            type='text'
                            label={t('label.vehicleMake')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.Field
                    // error={props.errors.currentVehicleModel || null}
                    defaultValue={props.values.currentVehicleModel || null}
                    onChange={value =>
                        props.handleFieldChange('currentVehicleModel', value)
                    }
                    type='text'
                    label={t('label.vehicleModel')}
                />
            </Form.Row>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field label={t('field.currentVehicleCondition.label')}>
                            <Form.Dropdown
                                // error={props.errors.currentVehicleCondition || null}
                                placeholder={t('field.currentVehicleCondition.placeholder')}
                                options={['extraClean', 'clean', 'average', 'rough', 'extraRough']}
                                optionFormat={'field.currentVehicleCondition.options.%name%'}
                                trans={t}
                                onChange={value => props.handleFieldChange('currentVehicleCondition', value)}
                                defaultValue={props.values.currentVehicleCondition}
                            />
                        </Form.Field>
                    </Form.RowColumn>
                    <Form.RowColumn>
                        <Form.Field label={t('field.currentVehicleMileage.label')}>
                            <Form.Dropdown
                                placeholder={t('field.currentVehicleMileage.placeholder')}
                                options={DEFAULT_MILEAGE_OPTIONS}
                                optionFormat={'field.currentVehicleMileage.options.%name%'}
                                trans={t}
                                onChange={value => props.handleFieldChange('currentVehicleMileage', value ? parseInt(value, 10) : '')}
                                defaultValue={defaultMileageOption.toString()}
                            />
                        </Form.Field>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <ButtonGroup className={styles.buttonGroup}>
                <Form.Row>
                    <Form.Field noBorder label={t('field.currentVehicleFinanceMethod.label')}>
                        <Form.RowColumns>
                            <Form.RowColumn className={styles.rowWrapper} size={1}>
                                {['cash', 'financed', 'leased'].map(choice => (
                                    <Button
                                        key={choice}
                                        onClick={() => props.handleFieldChange('currentVehicleFinanceMethod', props.values.currentVehicleFinanceMethod === choice ? null : choice)}
                                        style={props.values.currentVehicleFinanceMethod === choice ? theme.button : {
                                            border: `1px solid ${theme.button.background}`,
                                            color: theme.button.background
                                        }}>
                                        {t(`field.currentVehicleFinanceMethod.choice.${choice}`)}
                                    </Button>
                                ))}
                                <input
                                    tabIndex={-1}
                                    type='text'
                                    value={props.values.currentVehicleFinanceMethod ? `${props.values.currentVehicleFinanceMethod}` : ''}
                                    onChange={() => null}
                                    required={true}
                                    style={{
                                        opacity: 0,
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        top: 0,
                                        bottom: '20%',
                                        zIndex: 0
                                    }}
                                />
                            </Form.RowColumn>
                        </Form.RowColumns>
                    </Form.Field>
                </Form.Row>
            </ButtonGroup>

            <Form.Panel hidden={props.values.currentVehicleFinanceMethod !== 'leased'}>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.currentVehicleLeasePayment || null}
                            defaultValue={props.values.currentVehicleLeasePayment || null}
                            onChange={value => props.handleFieldChange('currentVehicleLeasePayment', value)}
                            type='currency'
                            locale={state.locale}
                            currency={state.currency}
                            label={t('field.currentVehicleLeasePayment.label')}
                            required={props.values.currentVehicleFinanceMethod === 'leased'}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.currentVehicleLeasePaymentsLeft || null}
                            defaultValue={props.values.currentVehicleLeasePaymentsLeft || null}
                            onChange={value =>
                                props.handleFieldChange('currentVehicleLeasePaymentsLeft', value)
                            }
                            type='number'
                            label={t('field.currentVehicleLeasePaymentsLeft.label')}
                            required={props.values.currentVehicleFinanceMethod === 'leased'}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Panel>

            <Form.Panel hidden={props.values.currentVehicleFinanceMethod !== 'financed'}>
                <Form.RowColumns>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.currentVehicleFinancePayment || null}
                            defaultValue={props.values.currentVehicleFinancePayment || null}
                            onChange={value =>
                                props.handleFieldChange('currentVehicleFinancePayment', value)
                            }
                            type='currency'
                            locale={state.locale}
                            currency={state.currency}
                            label={t('field.currentVehicleFinancePayment.label')}
                            required={props.values.currentVehicleFinanceMethod === 'financed'}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn>
                        <Form.Field
                            // error={props.errors.currentVehicleFinanceAmountOwing || null}
                            defaultValue={props.values.currentVehicleFinanceAmountOwing || null}
                            onChange={value =>
                                props.handleFieldChange('currentVehicleFinanceAmountOwing', value)
                            }
                            type='currency'
                            locale={state.locale}
                            currency={state.currency}
                            label={t('field.currentVehicleFinanceAmountOwing.label')}
                            required={props.values.currentVehicleFinanceMethod === 'financed'}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Panel>
            <Form.Row>
                <Form.Submit
                    isLoading={props.isLoading}
                    label={t('label.continueToStep3')}
                    style={{ background: theme.background, color: theme.color, marginTop: '1.62em' }}
                />
            </Form.Row>
        </div>
    )
}

export default withForm()(withTheme(supportedThemes)(Step2))
