// @flow
import * as React from 'react'
import cn from 'classnames'

import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Currency } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

function Cashback (props) {
    const { theme } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const incentives = state.campaign.incentives.manufacturer

    if (!incentives) return null

    return (
        <div className={styles.cashBack}>
            <h3 className={styles.cashBackTitle} style={{ color: theme.titleColor }}>
                <Trans i18nKey="cash_back">
                    Combine with <br/><strong>Cash Incentives</strong> up to
                </Trans>
            </h3>
            <span className={cn(styles.cashBackValue, { [styles.smallValues]: props.small })} style={{ color: theme.valueColor }}>{currencyFormatter.format(incentives.cashBack)}</span>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Cashback))
