
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns1f2622672572bc14c919b9df88f91f9fen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '1f2622672572bc14c919b9df88f91f9f', flattenObject(ns1f2622672572bc14c919b9df88f91f9fen_US), true, true);
        
            import ns1f2622672572bc14c919b9df88f91f9fen from './en.yaml'
            i18n.addResourceBundle('en', '1f2622672572bc14c919b9df88f91f9f', flattenObject(ns1f2622672572bc14c919b9df88f91f9fen), true, true);
        
        export default '1f2622672572bc14c919b9df88f91f9f';
    