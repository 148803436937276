// @flow

import * as React from 'react'

const EventLogo = React.forwardRef(function (props: Props, ref: React.Ref) {
    return (
        <>
            <svg width="276px" height="132px" viewBox="0 0 276 132" {...props} ref={ref}>
                <g
                    transform="translate(-69 -89) translate(-.929 89) translate(70.077)"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
            >
                    <path
                        fill="#4C4C4E"
                        d="M53.7525106 79.6344519L53.7525106 84.9325037 0 94.8709582 53.7525106 107.519548 53.7525106 132 275.637066 90.7485268 275.637066 38.3829787z"
            />
                    <g fill="#FEFEFE">
                        <path
                            d="M6.036 26.778v11.42L0 39.32V8.172l10.046-1.868c2.19-.407 3.76-.41 4.876-.202 3.638.694 6.036 3.655 6.036 8.098 0 4.36-2.398 8.172-6.036 10.26-1.117.665-2.81 1.188-4.67 1.533l-4.216.785zm3.967-6.512c1.53-.283 2.275-.63 2.894-1.078 1.24-.895 2.025-2.37 2.025-3.866 0-1.578-.785-2.76-2.025-3.194-.62-.258-1.364-.287-2.894-.002l-3.967.738v8.14l3.967-.738zM38.526 19.448v12.708l-5.993 1.115V2.12L42.081.346C43.487.085 44.728-.02 45.72.003 49.77.164 52.374 2.34 52.374 6.7c0 3.155-1.653 6.04-4.712 8.018v.04c2.687.458 4.091 1.94 4.34 5.092l.455 5.481c.123 1.556.579 3.008 1.736 3.665v.248l-6.448 1.2c-.785-.684-1.117-2.16-1.24-3.508l-.413-4.824c-.25-2.901-1.654-3.762-4.796-3.178l-2.77.515zm0-12.667v7.143l3.349-.622c2.853-.53 4.423-2.442 4.423-4.727 0-1.703-.951-2.482-2.48-2.572-.539-.025-1.281.032-2.109.185l-3.183.593z"
                            transform="translate(65.405 83.32)"
                />
                    </g>
                    <path
                        fill="#FEFEFE"
                        d="M132.679537 78.8089349L138.285714 77.7021277 138.285714 108.425108 132.679537 109.531915z"
            />
                    <path
                        d="M167.488 92.054l6.26-1.15c-.043 2.611-.338 4.426-.803 5.729-1.566 4.316-5.542 7.187-9.687 7.949l-.423.076c-4.104.754-8.08-.7-9.73-4.383-.55-1.198-.804-2.706-.804-4.678V85.274c0-1.974.255-3.572.804-4.974 1.65-4.29 5.626-7.203 9.73-7.957l.338-.06c4.145-.762 8.206.675 9.772 4.375.507 1.165.803 2.873.847 5.174l-6.262 1.15c-.042-1.42-.168-2.613-.508-3.265-.718-1.463-2.327-1.84-3.764-1.575l-.508.094c-1.48.272-3.002 1.222-3.764 2.957-.34.733-.51 1.898-.51 2.947V94.38c0 1.175.17 2.234.51 2.886.762 1.455 2.284 1.806 3.764 1.534l.508-.094c1.437-.263 3.002-1.138 3.764-2.914.34-.778.466-2.059.466-3.737"
                        fill="#FEFEFE"
            />
                    <path
                        fill="#FEFEFE"
                        d="M186.872587 68.5110625L192.478764 67.4042553 192.478764 98.1272353 186.872587 99.2340426z"
            />
                    <g fill="#FEFEFE">
                        <path
                            d="M5.557 19.35s.04 1.361.04 2.399v17.463L0 40.255V9.145l6.296-1.174 9.427 18.112.04-.008s-.04-1.363-.04-2.398V6.213l5.6-1.045V36.28l-6.341 1.182-9.385-18.12-.04.008zM45.03 32.272c-3.581.668-7.45-.767-9.055-4.407-.534-1.187-.782-2.676-.782-4.627V13.036c0-1.95.248-3.531.782-4.917C37.58 3.88 41.449 1 45.442.257l.33-.062c4.032-.751 7.985.667 9.508 4.324.411 1 .7 2.4.782 4.21l-6.093 1.134c-.08-.98-.205-1.827-.451-2.32-.701-1.445-2.265-1.816-3.665-1.557l-.494.093c-1.44.269-2.923 1.208-3.662 2.923-.329.726-.494 1.875-.494 2.913v10.12c0 1.16.165 2.21.494 2.854.739 1.439 2.304 1.768 3.745 1.499l.288-.053c2.222-.416 4.322-2.259 4.322-5.743v-.787l-4.65.867v-5.35l10.577-1.975v16.469l-2.593.484-.989-3.26-.04.01c-1.44 2.756-4.034 4.608-7.038 5.17l-.288.052z"
                            transform="translate(206.494 56.17)"
                />
                    </g>
                    <path
                        fill={props.eventColor}
                        d="M236.393822 0L0 43.4030274 0 95.4893617 236.393822 52.0882639z"
            />
                    <path
                        fill="#FEFEFE"
                        d="M8.40926641 85.1914894L8.40926641 51.7038738 29.8996139 47.7446809 29.8996139 55.7817086 17.1146067 58.1373231 17.1146067 62.6476266 27.7021307 60.695777 27.7021307 68.286941 17.1146067 70.2387906 17.1146067 75.5508832 29.8996139 73.1952687 29.8996139 81.2322964z"
            />
                    <g fill="#FEFEFE">
                        <path
                            d="M21.996 38.986V23.608c0-1.124.044-2.887.044-2.887l-.044.01-4.49 13.378-4.622.855L8.35 23.26l-.046.01s.046 1.746.046 2.87v15.377L0 43.064V9.34l9.427-1.748 5.746 14.717L20.92 5.462l9.427-1.747v33.724l-8.35 1.547zM36.627 36.274V2.55L48.434.36c2.468-.458 4.22-.423 5.477-.205 4.13.719 6.823 4.085 6.823 9.123 0 4.99-2.74 9.184-6.823 11.56-1.256.729-3.143 1.347-5.252 1.738l-3.324.617v11.466l-8.708 1.615zM48.39 14.533c1.031-.191 1.704-.496 2.154-.804a3.483 3.483 0 001.482-2.883c0-1.17-.54-2.058-1.482-2.378-.45-.14-1.123-.196-2.154-.006l-3.055.567v6.07l3.055-.566z"
                            transform="translate(34.571 37.447)"
                />
                    </g>
                    <path
                        fill="#FEFEFE"
                        d="M99.976834 68.3404255L99.976834 34.3916202 108.790641 32.7659574 108.790641 58.5670495 121.467181 56.2294317 121.467181 64.377145z"
            />
                    <path
                        d="M136.575 62.434c-4.69.889-8.847-.18-10.529-4.438-.53-1.392-.841-3.1-.841-5.23V41.534c0-2.128.31-3.954.841-5.55 1.682-4.894 5.84-7.538 10.53-8.427l.62-.118c4.69-.887 8.846.18 10.528 4.437.53 1.394.84 3.102.84 5.232V48.34c0 2.127-.31 3.954-.84 5.548-1.682 4.893-5.884 7.547-10.574 8.436l-.575.109zm.353-8.22c1.372-.26 2.258-.927 2.7-2.142.221-.631.354-1.427.354-2.604V39.231c0-1.177-.133-1.922-.355-2.47-.441-1.048-1.37-1.37-2.7-1.118l-.086.016c-1.327.252-2.258.926-2.7 2.143-.22.63-.353 1.425-.353 2.603v10.236c0 1.178.133 1.922.353 2.471.442 1.048 1.327 1.378 2.7 1.118l.087-.015z"
                        fill="#FEFEFE"
            />
                    <path
                        fill="#FEFEFE"
                        d="M159.615477 57.106383L159.615477 42.9034238 149.498069 25.0755794 158.898012 23.3174132 163.824349 33.4787223 163.912593 33.4612763 169.017338 21.4254945 178.46332 19.6595745 168.299873 41.1898417 168.299873 55.4819692z"
            />
                    <path
                        fill="#FEFEFE"
                        d="M181.266409 53.3617021L181.266409 19.8740866 202.756757 15.9148936 202.756757 23.9519214 189.969827 26.3075359 189.969827 30.8159258 200.559274 28.8659897 200.559274 36.4571538 189.969827 38.4070898 189.969827 43.721096 202.756757 41.3654814 202.756757 49.4025092z"
            />
                    <path
                        fill="#FEFEFE"
                        d="M208.362934 47.7446809L208.362934 14.2570653 229.853282 10.2978723 229.853282 18.3349001 217.068275 20.6905146 217.068275 25.1989045 227.655799 23.2489685 227.655799 30.8401325 217.068275 32.7900685 217.068275 38.1040747 229.853282 35.7484602 229.853282 43.7854879z"
            />
                </g>
            </svg>
        </>
    )
})

export default EventLogo
