
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns6f043e3a71b7150794e70b2ec9852a5cen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '6f043e3a71b7150794e70b2ec9852a5c', flattenObject(ns6f043e3a71b7150794e70b2ec9852a5cen_US), true, true);
        
            import ns6f043e3a71b7150794e70b2ec9852a5cen from './en.yaml'
            i18n.addResourceBundle('en', '6f043e3a71b7150794e70b2ec9852a5c', flattenObject(ns6f043e3a71b7150794e70b2ec9852a5cen), true, true);
        
        export default '6f043e3a71b7150794e70b2ec9852a5c';
    